<template>
  <div v-html="item.text.content" :class="item.css || 'container mx-auto mb-24 lg:px-36'" />
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'PageContent',
  extends: Blok
}
</script>

<style lang="scss" scoped>
  a {
    @apply text-blue;
    &:hover {
      @apply underline;
    }
  }
</style>
